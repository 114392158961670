import * as React from 'react';
import * as styles from './StickyLink.module.scss';

import { Link } from 'gatsby';

export const StickyLink = props => (
  <Link
    activeClassName={styles.active}
    className={styles.root}
    partiallyActive
    {...props}
  />
);
