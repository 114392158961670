/* eslint-disable no-useless-escape */
import * as React from 'react';
import { StaticQuery, graphql } from 'gatsby';

import Button from '../Button';
import CallOut from '../CallOut';
import Container from '../Container';
import FlexContainer from '../FlexContainer';
import Gallery from '../Gallery';
import Heading from '../Heading';
import Layout from '../Layout';
import ProductDetail from '../ProductDetail';
import RichText from '../RichText';
import StickyLink from '../StickyLink';
import StickyNav from '../StickyNav';

import { Cake, Cookie, Cupcake, Gift, Close, Palette } from '../../icons';

const ProductQuery = () => (
  <StaticQuery
    query={graphql`
      query myQuery {
        allContentfulProduct(sort: { fields: type }) {
          edges {
            node {
              id
              type
              menu
            }
          }
        }
      }
    `}
    render={({ allContentfulProduct }) => (
      <>
        {allContentfulProduct.edges.map(({ node }) => (
          <StickyLink key={node.id} to={`/products/${node.type}`}>
            {node.menu}
          </StickyLink>
        ))}
      </>
    )}
  />
);

const getPath = pathname => pathname.match(/([^\/]*)\/*$/)[1];

const GetIcon = ({ pathname }) => {
  switch (getPath(pathname)) {
    case 'cakes':
      return <Cake />;
    case 'cookies':
      return <Cookie />;
    case 'cupcakes':
      return <Cupcake />;
    case 'giftboxes':
      return <Gift />;
    case 'diy-cookie-kits':
      return <Palette />;
    default:
      return <Close />;
  }
};

export const Product = ({
  location,
  data: { title, body, gallery, newprod, pricing, orderButton, type },
}) => (
  <Layout location={location} pagetitle={title}>
    <StickyNav>
      <ProductQuery />
    </StickyNav>

    <Container>
      <Heading align="center">{title}</Heading>

      <CallOut icon={GetIcon(location)} newitem={newprod}>
        {body.childMarkdownRemark.html}
      </CallOut>
    </Container>

    {pricing && !pricing[0].hidden && (
      <Container>
        <FlexContainer align="stretch" gap justify="center" wrap="wrap">
          {pricing.map(detail => (
            <ProductDetail key={detail.id} price={detail.price}>
              <Heading align="center" level={3} lookslike={2} weight="bold">
                {detail.title}
              </Heading>
              {detail.description && (
                <RichText
                  dangerouslySetInnerHTML={{
                    __html: detail.description.childMarkdownRemark.html,
                  }}
                />
              )}
            </ProductDetail>
          ))}
        </FlexContainer>
      </Container>
    )}

    {orderButton && (
      <Container>
        <FlexContainer align="center" justify="center">
          <Button to={`/contact?p=${type}`}>Order Now</Button>
        </FlexContainer>
      </Container>
    )}

    {gallery && (
      <Container>
        <Gallery gallery={gallery} />
      </Container>
    )}
  </Layout>
);
