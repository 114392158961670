import * as React from 'react';
import { graphql } from 'gatsby';

import { Router, Redirect } from '@reach/router';
import Product from '../components/product';

const ProductPage = ({ data }) => (
  <Router>
    <Redirect from="/products/" noThrow to="/products/cakes" />
    <Product
      data={data.allContentfulProduct.edges[0].node}
      path="/products/:id?"
    />
  </Router>
);

export default ProductPage;

export const pageQuery = graphql`
  query ProductQuery($type: String) {
    allContentfulProduct(filter: { type: { eq: $type } }) {
      edges {
        node {
          type
          title
          newprod
          orderButton
          body {
            childMarkdownRemark {
              html
            }
          }
          pricing {
            id
            title
            price
            description {
              childMarkdownRemark {
                html
              }
            }
            hidden
          }
          gallery {
            id: contentful_id
            title
            file {
              url
            }
          }
        }
      }
    }
  }
`;
